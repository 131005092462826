import React from 'react';
import PropTypes from 'prop-types';
import './packagesList.scss';
import guitarImg from '../../../img/guitar.svg';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';
import { Link } from 'gatsby';
import * as _ from 'lodash';
import { getHeroImageSmallResPath } from '../../../helpers/images.helper';
import { findMainTag, isValidTag } from '../../../helpers/tags.helper';
import CustomBadge from '../../Common/CustomBadge/CustomBadge';

const PackagesList = ({ packages, className = '' }) => {

  return (
    <ul className={`${className} list-unstyled packages-list`}>
      {packages &&
        packages.map(( item, i) => {
          const title = item.title;
          const thumbnail = item.thumbnail;
          const path = `/packages/${item.id}-${_.kebabCase(item.title)}`
          const tag = findMainTag(item.tags) || null;

          return (
            <li key={i} className="mb-3 pb-3 pl-2 pr-2 border-divider packages-list-item">
              <Link to={path} className="flex-shrink-0 mr-3 thumbnail">
                <div className="thumbnail-container">
                  <div className="thumbnail-wrapper">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: thumbnail ? getHeroImageSmallResPath(thumbnail) : guitarImg,
                        alt: title,
                        className: 'hero-thumb',
                      }}
                    />
                  </div>
                </div>
              </Link>

              <div className={`${ isValidTag(tag) ? 'align-self-stretch' : ''}`}>
                {
                  isValidTag(tag) && (
                    <CustomBadge title={tag.title} color={tag.color} className={`package-card-badge mb-2`}/>
                  )
                }

                <Link to={path} className="link-primary package-title font-weight-bold d-block">
                  {title}
                </Link>
              </div>

              <Link to={path} className="d-none d-md-block btn-tg btn-tg-sale btn-view ml-0 ml-sm-auto">
                View
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

PackagesList.propTypes = {
  packages: PropTypes.array,
  className: PropTypes.string,
};

export default PackagesList;
